<template>
    <!-- 线下门店 -->
    <div v-loading="loading" class="page_body">
        <div class="separate-wrap" v-if="adListGG.adv_image != ''">
            <img :src="adListGG.adv_image" width="1210" />
        </div>

        <div class="areaFilters">
            <div class="areaFilters--label">地市</div>
            <div class="areaFilters--content">
                <div class="city">
                    <div class="items" :class="selectedAddressAll.cityId == 0 ? 'active' : ''" @click="handleClearCity('city')">全部</div>
                    <div class="items" :class="item.id == selectedAddressAll.cityId ? 'active' : ''" v-for="(item, index) in cityArr" :key="index" @click="handleSelectCity(item)">{{ item.name }}</div>
                </div>
            </div>

            <div class="areaFilters--label">区/县</div>
            <div class="areaFilters--content">
                <div class="city">
                    <div class="items" :class="selectedAddressAll.district == 0 ? 'active' : ''" @click="handleClearDistrict('district')">不限</div>
                    <div class="items" :class="item.id == selectedAddressAll.district ? 'active' : ''" v-for="(item, index) in districtArr" :key="index" @click="handleSelectDistrict(item)">{{ item.name }}</div>
                </div>
            </div>
            <div class="clear"></div>
        </div>
        <div class="shop-wrap" v-if="shopList.length">
            <div class="shop-item2" v-for="item in shopList" :key="item.id">
                <div class="shopitem_lst_body" v-if="item.store.length">
                    <div class="shopitem_top" v-for="(item3, ix) in item.store" :key="item3.store_id">
                        <div class="shopitem_top_item">
                            <div class="item-l">
                                <div class="avatar-wrap">
                                    <img class="avatar" :src="$img(item3.store_image || defaultShopImage)" @error="item.logo = defaultShopImage" />
                                </div>
                            </div>
                            <div class="item-r">
                                <div class="item_r_top">
                                    <div class="item-info title">{{ item3.store_name }}</div>
                                    <div class="item-info address">
                                        <img src="@/assets/images/store/address.png" />
                                        <span class="label">地址：</span>
                                        <span class="info-detail">{{ item3.full_address }}{{ item3.address }}</span>
                                    </div>
                                    <div class="item-info address">
                                        <img src="@/assets/images/store/time.png" />
                                        <span class="label">营业时间：</span>
                                        <span class="info-detail">{{ item3.open_date }}</span>
                                    </div>
                                </div>
                                <div class="item_r_bottom">
                                    <div class="bot_but" v-if="item3.is_pickup == 1">支持自提</div>
                                    <div class="bot_but" v-if="item3.is_o2o == 1">支持配送</div>
                                </div>
                            </div>
                        </div>
                        <div class="shop_division" v-if="ix < item.store.length - 1"></div>
                    </div>
                </div>
                <!-- 专馆数据 -->
                <div class="shop_info_body">
                    <div class="shop_info">
                        <div class="item-info title">{{ item.site_name }}</div>
                        <div class="item-info address">
                            <span class="label">地址：</span>
                            <span class="info-detail">{{ item.full_address }}{{ item.address }}</span>
                        </div>
                        <a class="store-entry" @click="toDetail(item.site_id)">进入专馆</a>
                    </div>
                </div>
            </div>
            <div class="pager">
                <el-pagination background :pager-count="1" :total="total" prev-text="上一页" next-text="下一页" :current-page.sync="currentPage" :page-size.sync="pageSize" @size-change="handlePageSizeChange" @current-change="handleCurrentPageChange" hide-on-single-page></el-pagination>
            </div>
        </div>
        <div v-else class="empty">
            <div class="ns-text-align">没有找到您想要的店铺。换个条件试试吧</div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex"
    import BreadCrumbs from "@/components/BreadCrumbs"
    import { getAreaList } from "@/api/address"
    import { shopPage2_0 } from "@/api/street"
    import {adList} from "@/api/website";

    export default {
        data: () => {
            return {
                shopList: [], // 店铺列表
                total: 0,
                keyword: "",
                currentPage: 1,
                pageSize: 6,
                loading: true,
                selectedAddressAll: {
                    provinceId: 0,
                    cityId: 0,
                    district: 0
                },
                cityArr: [],
                districtArr: [],
                filters: {
                    province: "",
                    shop_type: 0,
                    city: 0,
                    city_id: 0,
                    district: 0,
                    website_id: 0,
                    site_id: 0,
                    cat: 0,
                    website_type: 1,
                    type_id: 0,
                    order: "",
                    sort: "desc"
                },
                adListGG:{
                    adv_image:''
                },
            }
        },
        components: { BreadCrumbs },
        created() {
            this.filters.shop_type = this.$route.query.shoptype || 0

            this.keyword = this.$route.query.keyword || ""
            this.getShopList()
            /** 获取城市列表 */
            this.getAreaList(this.filters.province, "city")

            if(this.filters.shop_type == 3){
                this.getAdListGG('NS_PC_COUNTRYMAN') //广告位-51个乡下馆
            }else{
                this.getAdListGG('NS_PC_MAEPS') //广告位-农博园
            }
        },
        computed: {
            ...mapGetters(["defaultGoodsImage", "defaultShopImage"])
        },
        methods: {
            getAdListGG(keyword) {
                adList({ keyword: keyword })
                    .then(res => {
                        if(res.data.adv_list.length >0) {
                            this.adListGG = res.data.adv_list[0];
                        }
                    })
            },
            /******************************************** */
            getAreaList(pid, type) {
                getAreaList({ pid: pid }).then(res => {
                    if (type === "city") {
                        this.cityArr = res.data
                    } else if (type === "district") {
                        this.districtArr = res.data
                    }
                })
            },
            handlePageSizeChange(size) {
                this.pageSize = size
                this.currentPage = 1
                this.getShopList()
            },
            handleCurrentPageChange(page) {
                this.currentPage = page
                this.getShopList()
            },

            /** 切换城市显示门店 */
            handleSelectCity(item) {
                this.filters.city = item.id
                this.selectedAddressAll.cityId = item.id
                this.currentPage = 1
                this.getAreaList(item.id, "district")
                /** 获取线下门店列表 */
                this.getShopList()
            },
            handleClearCity() {
                this.selectedAddressAll.cityId = 0
                this.selectedAddressAll.district = 0
                this.filters.city = 0
                this.filters.district = 0
                this.districtArr = []
                this.currentPage = 1
                /** 获取线下门店列表 */
                this.getShopList()
            },
            handleSelectDistrict(item) {
                this.filters.district = item.id
                this.selectedAddressAll.district = item.id
                this.currentPage = 1
                /** 获取线下门店列表 */
                this.getShopList()
            },
            handleClearDistrict() {
                this.filters.city = this.selectedAddressAll.cityId
                this.selectedAddressAll.district = 0
                this.filters.district = 0
                this.currentPage = 1
                /** 获取线下门店列表 */
                this.getShopList()
            },
            /******************************************** */
            /** 获取线下门店列表 */
            getShopList() {
                shopPage2_0(this.filters || {})
                    .then(res => {
                        const { count, page_count, list } = res.data
                        this.total = count
                        this.shopList = list
                        this.loading = false
                    })
                    .catch(err => {
                        this.loading = false
                    })
            },

            handlePageSizeChange(size) {
                this.pageSize = size
                this.getShopList()
            },
            handleCurrentPageChange(page) {
                this.currentPage = page
                this.getShopList()
            },
            changeSort(type) {
                this.filters.order = type
                if (this.filters.order === type) {
                    this.$set(this.filters, "sort", this.filters.sort === "desc" ? "asc" : "desc")
                } else {
                    this.$set(this.filters, "order", type)
                    this.$set(this.filters, "sort", "desc")
                }
                this.getShopList()
            },
            toDetail(site_id) {
                this.$router.pushToTab({
                    path: "/shop-" + site_id
                })
            }
        },
        watch: {
            $route: function(curr) {
                if (this.keyword !== curr.query.keyword) {
                    this.currentPage = 1
                    this.keyword = curr.query.keyword
                    this.getShopList()
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .separate-wrap{display: block;margin: 20px auto}

    .page_body {
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: #f7f7f7;
    }

    .areaFilters {
        width: 1210px;
        background: #fff;
        margin: 20px auto;
        padding: 10px 0px 15px 0px;
        min-height: 60px;
        position: relative;
        border: 2px solid #d81e01;
        border-radius: 8px;
        background-color: #fff;
        .areaFilters--label {
            float: left;
            margin-right: 10px;
            line-height: 30px;
            text-align: right;
            font-size: 18px;
            color: #d81e01;
            font-weight: 600;
            width: 60px;
            display: block;
        }
        .areaFilters--content {
            float: left;
            width: 1140px;
            margin-bottom: 5px;
            display: block;
            .items {
                display: inline-block;
                padding: 0 10px;
                cursor: pointer;
                font-size: 16px;
                line-height: 30px;
                color: #999;
            }
            .items:hover,
            .items.active {
                color: #d81e01;
            }
        }
        .clear {
            clear: both;
            zoom: 1;
        }
    }
    .search_bread {
        display: inline-block;
        font-size: 14px;
        margin: 0px auto;
        width: 100%;
        padding: 10px;
    }

    .filters {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        .sort {
            display: flex;
            align-items: center;
            border: 1px solid #f1f1f1;

            .item {
                display: flex;
                align-items: center;
                padding: 5px 15px;
                border-right: 1px solid #f1f1f1;
                cursor: pointer;
                &:last-child {
                    border: none;
                }
                &:hover {
                    background: $base-color;
                    color: #fff;
                }
            }
        }
    }

    .shop-wrap {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;

        .shop-item2 {
            width: calc(100% - 80px);
            margin: 32px 0 8px 0;
            background-color: #ffffff;
            box-sizing: border-box;

            .shop_info_body {
                display: flex;
                margin: 10px 62px 62px 62px;
                background-color: #ececec;
                box-sizing: border-box;

                .shop_info {
                    width: 990px;
                    float: left;
                    color: #f8f8f8;
                    margin: 17px 24px;
                    display: flex;
                    flex-direction: column;
                    align-items: left;
                    justify-content: center;
                    position: relative;
                    .title {
                        font-size: 20px;
                        line-height: 28px;
                        color: #000000;
                        margin-bottom: 8px;
                    }
                    .address {
                        font-size: 14px;
                        line-height: 20px;
                        color: #727272;
                    }
                    .store-entry {
                        width: 165px;
                        height: 46px;
                        border-radius: 25px;
                        background: #cc0000;
                        font-size: 18px;
                        line-height: 46px;
                        text-align: center;
                        color: #ffffff;
                        float: right;
                        position: absolute;
                        right: 0px;
                        top: 6px;
                        cursor: pointer;
                    }
                }
            }

            .shopitem_lst_body {
                margin: 2px 62px 40px 62px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                .shopitem_top {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    .shop_division {
                        height: 0;
                        width: 100%;
                        margin-top: 40px;
                        border-bottom: 1px solid #e3e3e3;
                    }

                    .shopitem_top_item {
                        box-sizing: border-box;
                        width: 100%;
                        margin: 40px 0 0 0;
                        display: flex;
                        align-items: left;

                        .item-l {
                            width: 288px;
                            height: 179px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            overflow: hidden;
                            .avatar {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .item-r {
                            // width: calc(100% -288px);
                            width: 680px;
                            height: 179px;
                            margin-left: 42px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;

                            .item_r_top {
                                color: #727272;
                                display: flex;
                                flex-direction: column;
                                align-items: left;
                                justify-content: left;

                                position: relative;
                                .item-info {
                                    display: flex;
                                    align-items: center;
                                    font-size: 14px;
                                    line-height: 20px;
                                    img {
                                        width: 20px;
                                        margin-right: 12px;
                                    }
                                    .label {
                                    }
                                }
                                .title {
                                    font-size: 24px;
                                    line-height: 33px;
                                    font-family: PingFang SC;
                                    margin-bottom: 5px;
                                }
                                .address {
                                    margin-top: 13px;
                                }
                            }
                            .item_r_bottom {
                                display: flex;
                                .bot_but {
                                    width: 89px;
                                    height: 29px;
                                    font-size: 14px;
                                    line-height: 29px;
                                    border-radius: 50px;
                                    border: 1px solid #d81e01;
                                    color: #d81e01;
                                    text-align: center;
                                    margin-right: 12px;
                                }
                            }
                        }
                    }
                }
            }
            .shop_name {
                font-size: 14px;
            }
        }
        .shop-item {
            border: 1px solid #f1f1f1;
            padding: 15px;
            padding-bottom: 0;
            position: relative;
            margin-right: 12px;
            margin-top: 22px;
            cursor: pointer;
            &:nth-child(4n) {
                margin-right: initial;
            }
            .banner-wrap {
                // width: 260px;
                height: 166px;
                overflow: hidden;
                display: block;
                text-align: center;
                img {
                    display: inline-block;
                    max-height: 166px;
                }
            }
            .avatar-wrap {
                width: 80px;
                height: 80px;
                line-height: 80px;
                border-radius: 50%;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid #e9e9e9;

                .avatar {
                    background-color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .name-wrap {
                display: flex;
                justify-content: center;
                .name {
                    margin-top: 50px;
                    font-size: $ns-font-size-lg;
                    text-align: center;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    width: 170px;
                }
            }
            .main-wrap {
                display: flex;
                justify-content: center;
                p {
                    color: #838383;
                }
                .main {
                    display: flex;
                    justify-content: center;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    max-width: 140px;
                }
            }
            .shop-bottom {
                display: flex;
                border-top: 1px solid #f1f1f1;
                margin-top: 10px;
                .item {
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-right: 1px solid #f1f1f1;
                    padding: 10px 0;
                    p {
                        color: #838383;
                    }
                }
                .item-fan {
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px 0;
                    p {
                        color: #838383;
                    }
                }
            }
        }
    }
    .pager {
        text-align: center;
        margin: 15px 0;
    }
    .empty {
        line-height: 500px;
    }
</style>
